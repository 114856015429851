import request from '@/utils/request'


// 查询所有监控点
export function findCameraResources(params) {
    return request({
        url: `/hikvision/resources`,
        method: 'get',
        params: params
    })
}

export function GetCameraPreviewUrl(params) {
    return request({
        url: `/hikvision/preview_url`,
        method: 'get',
        params: params
    })
}
