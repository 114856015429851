import request from '@/utils/request'

// 列出所有摄像头
export function findCameras(params) {
  return request({
    url: `/cameras`,
    method: 'get',
    params: params
  })
}

// 更新摄像头
export function updateCamera(id, data) {
  return request({
    url: `/cameras/${id}`,
    method: 'put',
    data
  })
}

// 创建摄像头
export function createCamera(data) {
  return request({
    url: `/cameras`,
    method: 'post',
    data
  })
}

// 删除摄像头
export function deleteCamera(id) {
  return request({
    url: `cameras/${id}`,
    method: 'delete'
  })
}
