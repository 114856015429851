<template>
  <div>
    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="deleteCamera(record.id)" class="custom-delete">删除</a>
          <a v-if="hasVideoAuthority" @click="showVideoModal(record)">视频</a>
          <!--          <a @click="showOperationHistoryModal(record.id)">操作历史</a>-->
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
<!--    <video src="http://222.184.250.26:83/openUrl/jUcwnK0/live.m3u8"></video>-->
    <!-- 新增模态框 -->
    <new
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      :equipment-id="equipmentId"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findCameras, deleteCamera } from '@/api/camera'
import {GetCameraPreviewUrl} from "@/api/hikvision";

export default {
  name: 'CameraList',
  components: {
    Pagination,
    New: () => import('@/views/cameras/New'),
    Edit: () => import('@/views/cameras/Edit'),
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingRecord: {}, // 正在编辑的记录id
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      pagination: {
        total_count: 0
      },
      isShowVideoModal: false,
      videoCode: ''
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    hasVideoAuthority() {
      return this.$store.getters.videoAuthority === true || this.$store.getters.userRole === 'super_admin'
          || this.$store.getters.userRole === 'tenant_admin'
    },
    equipmentId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: '摄像头名称',
          dataIndex: 'name'
        },
        {
          title: '摄像头编码',
          dataIndex: 'code'
        },
        {
          title: '通道号',
          dataIndex: 'no'
        },
        {
          title: '厅房名称',
          dataIndex: 'hall_name'
        },
        {
          title: '操作',
          width: 220,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    showVideoModal(record) {
     this.getCameraPreviewUrl(record.code)
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findCameras(Object.assign({}, { equipment_id: this.equipmentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },
    getCameraPreviewUrl(cameraCode){
      GetCameraPreviewUrl({camera_code: cameraCode}).then(res => {
        if (res.code === 0){
          this.previewUrl = res.data.url
          window.open(window.customConfig.hikvision_video + '?code='+ this.previewUrl, '_blank');
        }
      })
    },
    deleteCamera(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？删除后无法恢复',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteCamera(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
